import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import ArrowRight from '../img/inline/arrow-right.svg'

const ViewAllButton = ({ text, slug }) => {
    return (
        <a
            href={slug}
            className={cx(
                'inline-flex justify-between items-center min-w-225 bg-gray-100 py-12 px-24',
                'text-14 text-gray-700 font-semibold leading-tight tracking-wide rounded-6',
                'group hover:bg-blue-700 focus:bg-blue-700 hover:text-white focus:text-white duration-200'
            )}
        >
            <span>View All {text}</span>
            <ArrowRight
                className={cx(
                    'inline-block text-blue-500 ml-12 group-hover:text-green-400 group-focus:text-green-400 duration-200'
                )}
            />
        </a>
    )
}

ViewAllButton.propTypes = {
    text: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
}

export default ViewAllButton
