import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from './Image'
import formatContentDate from './utils/formatContentDate'

const AuthorCard = ({ authorName, authorTitle, authorImage, date }) => {
    const name = authorTitle ? `${authorName}, ${authorTitle}` : authorName
    const contentDate = formatContentDate(date)

    return (
        <div className={cx('flex items-center space-x-16')}>
            <div className={cx('relative w-56 h-56 flex-shrink-0')}>
                <div
                    className={cx(
                        'absolute gradient-seafoam-glow w-full h-full rounded-full'
                    )}
                />
                <Image
                    image={authorImage}
                    alt={name}
                    className={cx('rounded-full border-4 border-transparent')}
                />
            </div>
            <div className={cx('leading-snug md-d:text-16')}>
                <p className={cx('font-semibold')}>{name}</p>
                <time dateTime={date}>{contentDate}</time>
            </div>
        </div>
    )
}

AuthorCard.propTypes = {
    authorName: PropTypes.string,
    authorTitle: PropTypes.string,
    authorImage: PropTypes.object,
    date: PropTypes.string,
}

export default AuthorCard
