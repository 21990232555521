import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from './Image'
import Markdown from 'react-markdown'
import AuthorCard from './AuthorCard'
import ArrowRight from '../img/inline/arrow-right.svg'

const SimpleBlogPost = ({ post, slug }) => {
    const { title, authorData, date, featuredImage, summary } = post

    return (
        <article
            className={cx(
                'relative h-full min-h-380 text-white duration-200 z-1',
                'transform hover:shadow-lg lg:hover:scale-105 lg:focus:scale-105',
                {
                    'bg-purple-700': !featuredImage,
                }
            )}
        >
            <a href={slug} className={cx('flex flex-col h-full group')}>
                {featuredImage && (
                    <>
                        <div className={cx('w-full absolute inset-0 -z-1')}>
                            <Image
                                image={featuredImage}
                                className={cx('w-full h-full')}
                            />
                        </div>
                        <div
                            className={cx(
                                'w-full absolute inset-0 -z-1 bg-black bg-opacity-70'
                            )}
                        />
                    </>
                )}
                <div className={cx('p-24 md:p-40 pb-0')}>
                    <h2 className={cx('text-21 font-medium leading-snug')}>
                        {title}
                    </h2>
                    {summary && (
                        <Markdown
                            source={summary}
                            className="rich-text mt-20 mb-24"
                        />
                    )}
                </div>
                {authorData && (
                    <div
                        className={cx(
                            'flex items-center justify-between border-t border-purple-900 mt-auto p-24 md:px-40'
                        )}
                    >
                        {date && (
                            <AuthorCard
                                authorName={authorData.authorName}
                                authorTitle={authorData.authorTitle}
                                authorImage={authorData.authorImage}
                                date={date}
                            />
                        )}

                        <div
                            className={cx(
                                'md-d:hidden inline-block bg-white rect-nav-button rounded-full',
                                'flex-shrink-0 flex items-center justify-center ml-36 duration-200'
                            )}
                        >
                            <ArrowRight
                                className={cx(
                                    'text-purple-700 transform duration-200',
                                    'group-hover:translate-x-4 group-focus:translate-x-4'
                                )}
                            />
                        </div>
                    </div>
                )}
            </a>
        </article>
    )
}

SimpleBlogPost.propTypes = {
    post: PropTypes.object,
    slug: PropTypes.string,
}

export default SimpleBlogPost
