import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import FeaturedBlogPost from '../components/FeaturedBlogPost'
import SimpleBlogPost from '../components/SimpleBlogPost'

export const BlogIndexPageTemplate = ({
    title,
    introduction,
    featuredBlogPost,
    posts,
}) => {
    return (
        <>
            <section
                className={cx(
                    'max-w-1264 mx-auto px-24 lg:px-32 my-60 lg:mb-90'
                )}
            >
                <h1 className={cx('hd-jb text-purple-700')}>{title}</h1>
                {introduction && (
                    <p className={cx('lg:w-1/2 text-21 leading-snug mt-24')}>
                        {introduction}
                    </p>
                )}
            </section>
            {featuredBlogPost && featuredBlogPost.node && (
                <FeaturedBlogPost
                    post={featuredBlogPost.node.frontmatter}
                    slug={featuredBlogPost.node.fields.slug}
                    isBlogIndex={true}
                />
            )}
            {posts && posts.length > 1 && (
                <section className={cx('bg-purple-800 py-80 md:py-128')}>
                    <ul
                        className={cx(
                            'grid lg:grid-cols-2 gap-40 max-w-1264 mx-auto px-24 md:px-40 xl-px-0'
                        )}
                    >
                        {posts.map((post) => (
                            <li key={post.node.id}>
                                <SimpleBlogPost
                                    post={post.node.frontmatter}
                                    slug={post.node.fields.slug}
                                />
                            </li>
                        ))}
                    </ul>
                </section>
            )}
        </>
    )
}

BlogIndexPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    featuredBlogPost: PropTypes.object,
    posts: PropTypes.array,
}

const BlogIndexPage = ({ data }) => {
    const { frontmatter } = data.page
    const authors = data.authors.edges
    let posts = data.posts.edges
    let featuredBlogPost = posts[0]

    posts.forEach((post) => {
        let author = post.node.frontmatter.author
        let authorMatch = authors.find(
            (item) => item.node.frontmatter.authorName === author
        )
        if (authorMatch)
            post.node.frontmatter.authorData = authorMatch.node.frontmatter

        if (post.node.frontmatter.title === frontmatter.featuredBlogPost) {
            featuredBlogPost = post
            posts = posts.filter((p) => p !== featuredBlogPost)
        }
    })

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <BlogIndexPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                featuredBlogPost={featuredBlogPost}
                posts={posts}
            />
        </Layout>
    )
}

BlogIndexPage.propTypes = {
    data: PropTypes.shape({
        page: PropTypes.object,
        posts: PropTypes.object,
        authors: PropTypes.object,
    }),
}

export default BlogIndexPage

export const pageQuery = graphql`
    query BlogIndexPageTemplate {
        page: markdownRemark(
            frontmatter: { templateKey: { eq: "blog-index-page" } }
        ) {
            frontmatter {
                title
                introduction
                featuredBlogPost
            }
        }
        posts: allMarkdownRemark(
            sort: { order: DESC, fields: frontmatter___date }
            filter: { frontmatter: { templateKey: { eq: "blog-post-page" } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        author
                        date
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1440, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        summary
                    }
                }
            }
        }
        authors: allMarkdownRemark(
            sort: { fields: frontmatter___date }
            filter: { fileAbsolutePath: { regex: "/(_authors)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        authorName
                        authorTitle
                        authorImage {
                            childImageSharp {
                                fluid(maxWidth: 100, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
