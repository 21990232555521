import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ViewAllButton from './ViewAllButton'

const ResourceBlockHeading = ({
    heading,
    headingLevel = 2,
    viewAllText,
    viewAllSlug,
}) => {
    const Heading = `h${headingLevel}`
    return (
        <div
            className={cx(
                'md:flex md:justify-between md:items-center md-d:space-y-36 mb-64'
            )}
        >
            <Heading className={cx('hd-sm')}>{heading}</Heading>
            <ViewAllButton text={viewAllText} slug={viewAllSlug} />
        </div>
    )
}

ResourceBlockHeading.propTypes = {
    heading: PropTypes.string,
    headingLevel: PropTypes.number,
    viewAllText: PropTypes.string,
    viewAllSlug: PropTypes.string,
}

export default ResourceBlockHeading
