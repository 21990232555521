import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from './Image'
import AuthorCard from './AuthorCard'
import Markdown from 'react-markdown'
import ResourceBlockHeading from './ResourceBlockHeading'
import ArrowRight from '../img/inline/arrow-right.svg'

const FeaturedBlogPost = ({
    post,
    slug,
    isArticleEntry,
    isExpandedComponent,
    className,
}) => {
    const { title, authorData, date, featuredImage, summary } = post
    const WrapperEl = slug ? 'a' : 'div'
    const HeadingEl = isArticleEntry ? 'h1' : 'h2'

    return (
        <section
            className={cx(
                className,
                'relative flex min-h-650 lg:h-760 text-white',
                {
                    'lg:items-center': !isExpandedComponent,
                    'bg-purple-700': !featuredImage,
                }
            )}
        >
            {featuredImage && (
                <>
                    <div className={cx('w-full absolute inset-0 -z-1')}>
                        <Image
                            image={featuredImage}
                            className={cx('w-full h-full')}
                        />
                    </div>
                    <div
                        className={cx(
                            'w-full absolute -z-1 inset-0 bg-black bg-opacity-70'
                        )}
                    />
                </>
            )}
            <div
                className={cx('max-w-1264 mx-auto px-24 md:px-40 xl-px-0', {
                    'lg-d:pt-128 lg-d:pb-80': isArticleEntry,
                    'py-80': !isArticleEntry,
                    'pt-64': isExpandedComponent,
                })}
            >
                {(isArticleEntry || isExpandedComponent) && (
                    <div
                        className={cx({
                            'mb-80': isExpandedComponent,
                        })}
                    >
                        {!isExpandedComponent && (
                            <a
                                href="/blog"
                                className={cx('block hd-sm mb-40 lg:mb-20')}
                            >
                                Blog
                            </a>
                        )}
                        {isExpandedComponent && (
                            <ResourceBlockHeading
                                heading="Blog"
                                viewAllText="Articles"
                                viewAllSlug="/blog"
                            />
                        )}
                        {isExpandedComponent && (
                            <div
                                className={cx(
                                    'w-full h-px gradient-teal-to-violet'
                                )}
                            />
                        )}
                    </div>
                )}
                <WrapperEl
                    href={slug}
                    className={cx('lg:grid lg:grid-cols-2 gap-120')}
                >
                    <div>
                        <HeadingEl className={cx('hd-jb md:hd-jbb mb-36')}>
                            {title}
                        </HeadingEl>

                        {(authorData || date) && (
                            <AuthorCard
                                authorName={authorData.authorName}
                                authorTitle={authorData.authorTitle}
                                authorImage={authorData.authorImage}
                                date={date}
                            />
                        )}
                    </div>
                    <div>
                        {summary && (
                            <Markdown
                                source={summary}
                                className="rich-text lg-d:mt-40"
                            />
                        )}
                        {!isArticleEntry && (
                            <div
                                className={cx(
                                    'flex items-center justify-center flex-shrink-0 duration-200',
                                    'bg-white rect-nav-button ml-auto mt-16 rounded-full group'
                                )}
                            >
                                <ArrowRight
                                    className={cx(
                                        'text-purple-700 transform duration-200',
                                        'group-hover:translate-x-4 group-focus:translate-x-4'
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </WrapperEl>
            </div>
        </section>
    )
}

FeaturedBlogPost.propTypes = {
    post: PropTypes.object,
    slug: PropTypes.string,
    isArticleEntry: PropTypes.bool,
    isExpandedComponent: PropTypes.bool,
    className: PropTypes.string,
}

export default FeaturedBlogPost
